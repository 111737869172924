body.not-logged-in {
    background: $gray-700;

    .top-bar {
        margin-bottom: auto;
    }

    .main-content {
        align-items: center;
    }

    .main-container {
        padding-top: 0;
        padding-bottom: 0;
        //max-width: 30rem;
    }

    .user-panel-wrapper {
        margin: auto 0;
        max-width: 33.4rem;

        .g-recaptcha {
            margin-bottom: 1.5rem;
        }

        .panel {

            h2 {
                font-weight: 700;
            }

            .region-content {
                @extend .panel-body;
            }
        }
    }

    form#user-login, form#user-login-form {
        font-family: $headings-font-family;
        label {
            font-weight: 500;
        }
        .form-item-pass label {
            display: flex;

            &:before, &:after {
                display: none;
            }

            a {
                font-weight: normal;
            }

            span {
                order: -2;

                &.form-required {
                    margin-right: auto;
                    order: -1;
                }
            }
        }

        #edit-submit {
            display: block;
            border-radius: $border-radius-base;
            width: 100%;
            @extend .btn-primary;
            @extend .btn-lg;
            text-transform: uppercase;
        }

        #edit-actions {
            margin-bottom: 0;
        }
    }
    
    footer {
        text-align: center;
    }
}