.field-name-field-client-uid {
    display: none;
}


.webform-component-dragndrop .form-managed-file {
    display: block !important;

    .form-control, .input-group-btn {
        display: none;
    }
    .field-widget-dragndrop-upload-file {
        width: 100%;
    }

} 

.form-managed-file .file-icon {
    display: none;
}
.webform-component .form-managed-file {
    display: flex;

    &:before, &:after {
        display: none;
    }
    
    .input-group-btn {
        width: auto;
        display: flex;

        &:before, &:after {
            display: none;
        }
        
    }
    
}