.btn {
    border: 0;
    font-family: $headings-font-family;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: .2s all;

    &-default {
        //border: 2px solid $gray-400 !important;

        .fa, .fas {
            color: $gray-400;
            transition: .2s all;

        }

        &:hover, &:focus {
            box-shadow: inset 0px 0px 9px rgba($brand-primary, .6);
            background: white;

            .fa, .fas {
                color: $brand-primary;
            }
        }

        &.active, &.active:hover, &.active:focus {
            border-color: $brand-primary;
            color: $brand-primary;
            box-shadow: inset 0px 0px 9px rgba($brand-primary, .6);
            background: $brand-primary-300;

            .fa, .fas {
                color: $brand-primary;
            }
        }

    }

    &-primary {

    }

    &-info {

    }

    &-success {

    }

    &-danger {

    }

}