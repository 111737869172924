.view-properties, .view-properties-wrapper {
    &.view-display-id-block_1 {
        .client-col {
            display: none !important;
        }

        .property-col {
            @extend .col-md-4;
        }

    }

    .views-exposed-widgets {
        margin-bottom: 0;
    }
    .list-view {
        display: none;
    }
    &[data-layout='list'] {
        .view-content {
            border-radius: $border-radius-base;
            @extend .box-shadow--small;
        }

        .list-view {
            display: block;
        }
        .grid-view {
            display: none;
        }
        .col-sm-6 {
            @extend .col-sm-12;
            @extend .col-md-12;

        }
    }

    &[data-layout='grid'] {
        .grid-view {
            display: block;
        }
        .list-view {
            display: none;
        }
    }

    .view-content > .row > div:last-child .list-view {
        border-bottom-left-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
    }

    .node h4 {
        word-wrap: break-word; 
    }
}

.view-display-id-block_2 .view-filters {
    display: none !important;
}

#views-exposed-form-properties-page, #views-exposed-form-properties-block-1, #views-exposed-form-properties-block-2 {
    .views-exposed-widgets {
        display: flex;
        align-items: center;
        @extend .row;

        &:before, &:after {
            display: none;
        }
    }
    .views-exposed-widget {
        padding: 0 1.5rem;
    }
    
    label {
        margin: 0;
        padding: 0;
    }

    input {
        opacity: 0;
        margin: 0;
        position: absolute;
    }

    .fas {
        font-size: 1.8rem;
    }

    .dropdown-menu {
        color: $text-color;

        .form-item {
            @extend li;
            //border-bottom: solid 1px $gray-450;

            &:last-child {
                border-bottom: none;
            }
        }

        label {
            padding: 1rem;
        }
    }

    .form-item .btn-group {
        .btn {
            padding: 0;

            > label {
                padding: $padding-base-vertical $padding-base-horizontal;
            }
        }
        .radio {
            margin: 0;
        }
    }

    #edit-field-status-value-wrapper #edit-field-status-value {

    }
}