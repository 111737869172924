.main-container > .row > .sidebar {
    //@extend .full-width;
    background: white;
    position: relative;
    box-shadow: 1px 0px 10px rgba(black, .1);
    z-index: 9;

    .menu {
        margin-top: 2rem;
        font-family: $headings-font-family;
        font-size: 1.6rem;
        text-transform: uppercase;

        a {
            color: $text-color;
            padding: 1.5rem 2rem;
            margin-bottom: 1px;

            &:hover, &:focus, &.active {
                background: $gray-400;
            }

            &.active {
                border-right: solid 3px $brand-primary;
            }
        }
    }
}