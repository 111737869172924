.breadcrumb {
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;

    a {
        color: $gray-400;
    }

    li:before {
        color: $gray-400 !important;
        padding: 0 1.5rem !important;
    }
}

.sticky-breadcrumb {
    width: 100% !important;

    @include bp-md {
        font-size: 1.6rem;
    }

    .breadcrumb {
        margin-bottom: 0;
    }
    

    .is-sticky & {
        width: 100% !important;
        right: 0;
        left: 0 !important;
        background: $gray-400;
        @extend .box-shadow;
        z-index: 8 !important;

        .breadcrumb-container {
            @extend .container;
        }

        .breadcrumb {
            padding: 1.5rem;
            @extend .col-md-9;

            @include bp-md {
                margin-left: calc(25% - 8px);
            }
            
        }

    }
}