.nav-tabs:not(.tab-dropdown--nav-tabs) {
    //border-bottom: none;
    margin: 0;

    &.nav-tabs--dark {
        a {
            color: $navbar-default-link-color;

            &:hover, &:focus {
                color: $navbar-default-link-hover-color;
            }
        }

        li {
            &.active {
                a {
                    color: $navbar-default-link-active-color;
                }
            }
        }
    }

    li {
        font-size: 1.6rem;
        padding: 0 1rem;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
        &.active {
            a {
                border-color: $brand-secondary !important;
            }
        }
    }

    a {
        color: $gray-500;
        padding: 1rem 0;
        border: none !important;
        background: transparent !important;
        border-bottom: solid 3px transparent !important;
    }
}