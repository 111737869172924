/*
|--------------------------------------------------------------------------
| Breakpoints
|--------------------------------------------------------------------------
*/

$grid-breakpoints: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: map-merge(
        (
                xs: 0,
                sm: 768px,
                md: 992px,
                lg: 1200px,
                xl: 1400px,
        ),
        $grid-breakpoints
);

/*
|--------------------------------------------------------------------------
| Spacers
|--------------------------------------------------------------------------
*/
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacer: 1rem;
$spacers: (
        0: 0,
        0_5: ($spacer * 0.5),
        1: $spacer,
        _4: ($spacer * .4),
        _5: ($spacer * .5),
        1_2: ($spacer * 1.2),
        1_5: ($spacer * 1.5),
        2: ($spacer * 2),
        3: ($spacer * 3),
        4: ($spacer * 4),
        5: ($spacer * 5),
        6: ($spacer * 6),
        7: ($spacer * 7),
        8: ($spacer * 8),
        9: ($spacer * 9),
        10: ($spacer * 10),
        n-0_5: (-$spacer * 0.5),
        n-1: -$spacer,
        n-_4: (-$spacer * .4),
        n-_5: (-$spacer * .5),
        n-1_2: (-$spacer * 1.2),
        n-1_5: (-$spacer * 1.5),
        n-2: (-$spacer * 2),
        n-3: (-$spacer * 3),
        n-4: (-$spacer * 4),
        n-5: (-$spacer * 5),
        n-6: (-$spacer * 6),
        n-7: (-$spacer * 7),
        n-8: (-$spacer * 8),
        n-9: (-$spacer * 9),
        n-10: (-$spacer * 10),
);


/*
|--------------------------------------------------------------------------
| Breakpoint
|--------------------------------------------------------------------------
*/

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($lower, $breakpoints) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($upper, $breakpoints) {
            @content;
        }
    }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    $max: breakpoint-max($name, $breakpoints);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($name, $breakpoints) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($name, $breakpoints) {
            @content;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (margin: m, padding: p) {
            @each $size, $length in $spacers {
                .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
                .#{$abbrev}t#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-top: $length !important;
                }
                .#{$abbrev}r#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }
                .#{$abbrev}b#{$infix}-#{$size},
                .#{$abbrev}y#{$infix}-#{$size} {
                    #{$prop}-bottom: $length !important;
                }
                .#{$abbrev}l#{$infix}-#{$size},
                .#{$abbrev}x#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }
            }
        }

        // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
        @each $size, $length in $spacers {
            @if $size != 0 {
                .m#{$infix}-n#{$size} { margin: -$length !important; }
                .mt#{$infix}-n#{$size},
                .my#{$infix}-n#{$size} {
                    margin-top: -$length !important;
                }
                .mr#{$infix}-n#{$size},
                .mx#{$infix}-n#{$size} {
                    margin-right: -$length !important;
                }
                .mb#{$infix}-n#{$size},
                .my#{$infix}-n#{$size} {
                    margin-bottom: -$length !important;
                }
                .ml#{$infix}-n#{$size},
                .mx#{$infix}-n#{$size} {
                    margin-left: -$length !important;
                }
            }
        }

        // Some special margin utils
        .m#{$infix}-auto { margin: auto !important; }
        .mt#{$infix}-auto,
        .my#{$infix}-auto {
            margin-top: auto !important;
        }
        .mr#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-right: auto !important;
        }
        .mb#{$infix}-auto,
        .my#{$infix}-auto {
            margin-bottom: auto !important;
        }
        .ml#{$infix}-auto,
        .mx#{$infix}-auto {
            margin-left: auto !important;
        }
    }
}


/*
|--------------------------------------------------------------------------
| Display Utils
|--------------------------------------------------------------------------
*/
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .d#{$infix}-none         { display: none !important; }
        .d#{$infix}-inline       { display: inline !important; }
        .d#{$infix}-inline-block { display: inline-block !important; }
        .d#{$infix}-block        { display: block !important; }
        .d#{$infix}-table        { display: table !important; }
        .d#{$infix}-table-row    { display: table-row !important; }
        .d#{$infix}-table-cell   { display: table-cell !important; }
        .d#{$infix}-flex         { display: flex !important; }
        .d#{$infix}-inline-flex  { display: inline-flex !important; }
    }
}

@media print {
    .d-print-none         { display: none !important; }
    .d-print-inline       { display: inline !important; }
    .d-print-inline-block { display: inline-block !important; }
    .d-print-block        { display: block !important; }
    .d-print-table        { display: table !important; }
    .d-print-table-row    { display: table-row !important; }
    .d-print-table-cell   { display: table-cell !important; }
    .d-print-flex         { display: flex !important; }
    .d-print-inline-flex  { display: inline-flex !important; }
}



/*
|--------------------------------------------------------------------------
| Flex Utils
|--------------------------------------------------------------------------
*/
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .flex#{$infix}-row            { flex-direction: row !important; }
        .flex#{$infix}-column         { flex-direction: column !important; }
        .flex#{$infix}-row-reverse    { flex-direction: row-reverse !important; }
        .flex#{$infix}-column-reverse { flex-direction: column-reverse !important; }

        .flex#{$infix}-wrap         { flex-wrap: wrap !important; }
        .flex#{$infix}-nowrap       { flex-wrap: nowrap !important; }
        .flex#{$infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }
        .flex#{$infix}-fill         { flex: 1 1 auto !important; }
        .flex#{$infix}-grow-0       { flex-grow: 0 !important; }
        .flex#{$infix}-grow-1       { flex-grow: 1 !important; }
        .flex#{$infix}-shrink-0     { flex-shrink: 0 !important; }
        .flex#{$infix}-shrink-1     { flex-shrink: 1 !important; }

        .justify-content#{$infix}-start   { justify-content: flex-start !important; }
        .justify-content#{$infix}-end     { justify-content: flex-end !important; }
        .justify-content#{$infix}-center  { justify-content: center !important; }
        .justify-content#{$infix}-between { justify-content: space-between !important; }
        .justify-content#{$infix}-around  { justify-content: space-around !important; }

        .align-items#{$infix}-start    { align-items: flex-start !important; }
        .align-items#{$infix}-end      { align-items: flex-end !important; }
        .align-items#{$infix}-center   { align-items: center !important; }
        .align-items#{$infix}-baseline { align-items: baseline !important; }
        .align-items#{$infix}-stretch  { align-items: stretch !important; }

        .align-content#{$infix}-start   { align-content: flex-start !important; }
        .align-content#{$infix}-end     { align-content: flex-end !important; }
        .align-content#{$infix}-center  { align-content: center !important; }
        .align-content#{$infix}-between { align-content: space-between !important; }
        .align-content#{$infix}-around  { align-content: space-around !important; }
        .align-content#{$infix}-stretch { align-content: stretch !important; }

        .align-self#{$infix}-auto     { align-self: auto !important; }
        .align-self#{$infix}-start    { align-self: flex-start !important; }
        .align-self#{$infix}-end      { align-self: flex-end !important; }
        .align-self#{$infix}-center   { align-self: center !important; }
        .align-self#{$infix}-baseline { align-self: baseline !important; }
        .align-self#{$infix}-stretch  { align-self: stretch !important; }
    }
}


/*
|--------------------------------------------------------------------------
| Sizing
|--------------------------------------------------------------------------
*/
// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
                (
                        25: 25%,
                        50: 50%,
                        75: 75%,
                        100: 100%,
                        auto: auto
                ),
                $sizes
);
// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
    @each $size, $length in $sizes {
        .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    }
}

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }

// Viewport additional helpers

.min-vw-100 { min-width: 100vw !important; }
.min-vh-100 { min-height: 100vh !important; }

.vw-100 { width: 100vw !important; }
.vh-100 { height: 100vh !important; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .text#{$infix}-left   { text-align: left !important; }
        .text#{$infix}-right  { text-align: right !important; }
        .text#{$infix}-center { text-align: center !important; }
    }
}