.full-width {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 768px) {
    margin-left: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    margin-right: calc(-50vw + 360px); // half of 720 (750px - 30px of padding)
    padding-left: calc(50vw - 360px);
    padding-right: calc(50vw - 360px);
  }

  @media (min-width: 992px) {
    margin-left: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    margin-right: calc(-50vw + 470px); // half of 940 (970px - 30px of padding)
    padding-left: calc(50vw - 470px);
    padding-right: calc(50vw - 470px);
  }

  @media (min-width: 1200px) {
    margin-left: calc(-50vw + 570px); // half of 1140 (1170px - 30px of padding)
    margin-right: calc(-50vw + 570px); // half of 11400 (1170px - 30px of padding)
    padding-left: calc(50vw - 570px);
    padding-right: calc(50vw - 570px);
  }
}

/*
|--------------------------------------------------------------------------
| Striped
|--------------------------------------------------------------------------
*/
.striped {
  &-row {
      &--item {
        @extend .clearfix;

        @include bp-md {
          padding-top: 1rem;
          padding-bottom: 1rem;
          margin-bottom: 1rem;
        }

        &:nth-child(odd) {
          @extend .border-radius-sm;
        }
    }

  }
}

/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
.box-shadow {
  box-shadow: 0px 1px 15px rgba(black, .3);

  &--small {
    box-shadow: 0px 1px 2px rgba(black, .3);

  }

  &-none {
    box-shadow: none !important;
  }
}
.bg {
  &-gray-200 {
    background: $gray-200 !important;
  }
  &-gray-300 {
    background: $gray-300 !important;
  }
  &-gray-400 {
    background: $gray-400 !important;
  }
  &-gray-500 {
    background: $gray-500 !important;
  }
  &-gray-600 {
    background: $gray-600 !important;
  }
  &-gray-700 {
    background: $gray-700 !important;
  }
  &-transparent {
    background: transparent !important;
  }
  &-white {
    background: white !important;
  }
  &-brand-primary {
    background: $brand-primary !important;
  }
  &-brand-primary-300 {
    background: $brand-primary-300 !important;
  }
  &-brand-secondary {
    background: $brand-secondary !important;
  }
  &-brand-success {
    background: $brand-success !important;

    &-700 {
      background: $brand-success-700 !important;
    }
  }
}

.text {
  &-gray-200 {
    color: $gray-200 !important;
  }
  &-gray-300 {
    color: $gray-300 !important;
  }
  &-gray-400 {
    color: $gray-400 !important;
  }
  &-gray-500 {
    color: $gray-500 !important;
  }
  &-gray-600 {
    color: $gray-600 !important;
  }
  &-gray-700 {
    color: $gray-700 !important;
  }
  &-primary {
    color: $brand-primary !important;
  }
  &-white {
    color: white !important;
  }
  &-brand-success {
    color: $brand-success !important;

    &-700 {
      color: $brand-success-700 !important;
    }

    &-300 {
      color: $brand-success-300 !important;
    }
  }
  
  &-uppercase {
    text-transform: uppercase;
  }
  &-lg {
    font-size: 1.6rem !important;
  }
  &-sm {
    font-size: 1.2rem !important;
  }
}

.lts {
  &-0_5 {
    letter-spacing: .5px;
  }
  &-1 {
    letter-spacing: 1px;
  }
  &-2 {
    letter-spacing: 2px;
  }
}

.font {
  &-weight-400 {
    font-weight: 400;
  }
  &-weight-500 {
    font-weight: 500;
  }
  &-weight-700 {
    font-weight: 700;
  }
  &-weight-black, &-weight-900 {
    font-weight: 900;
  }

  &-family-headings {
    font-family: $headings-font-family; // 500,900
  }
  &-family-base {
    font-family: $font-family-base; // 400,700
  }
}

.w {
  &-100 {
    width: 100%;
  }
}

.mh {
  &-100 {
    min-height: 100px;
  }
  &-150 {
    min-height: 150px;
  }
  &-200 {
    min-height: 200px;
  }
  &-250 {
    min-height: 250px;
  }
  &-300 {
    min-height: 300px;
  }
}


/*
|--------------------------------------------------------------------------
| Borders
|--------------------------------------------------------------------------
*/
.border {
  border: 1px solid transparent;

  &-none {
    border: none !important;

    &-sm {
      @include bp-sm {
        border: none !important;
      }
    }
    &-md {
      @include bp-md {
        border: none !important;
      }
    }
    &-lg {
      @include bp-lg {
        border: none !important;
      }
    }
  }



  &-top {
    border-top: 1px solid transparent !important;

    &-sm {
      @include bp-sm {
        border-top: 1px solid transparent !important;
      }
    }

    &-md {
      @include bp-md {
        border-top: 1px solid transparent !important;
      }
    }

    &-lg {
      @include bp-lg {
        border-top: 1px solid transparent !important;
      }
    }
  }
  &-bottom {
    border-bottom: 1px solid transparent !important;

    &-sm {
      @include bp-sm {
        border-bottom: 1px solid transparent !important;
      }
    }

    &-md {
      @include bp-md {
        border-bottom: 1px solid transparent !important;
      }
    }

    &-lg {
      @include bp-lg {
        border-bottom: 1px solid transparent !important;
      }
    }
  }
  &-left {
    border-left: 1px solid transparent !important;

    &-sm {
      @include bp-sm {
        border-left: 1px solid transparent !important;
      }
    }

    &-md {
      @include bp-md {
        border-left: 1px solid transparent !important;
      }
    }

    &-lg {
      @include bp-lg {
        border-left: 1px solid transparent !important;
      }
    }
  }
  &-right {
    border-right: 1px solid transparent !important;

    &-sm {
      @include bp-sm {
        border-right: 1px solid transparent !important;
      }
    }

    &-md {
      @include bp-md {
        border-right: 1px solid transparent !important;
      }
    }

    &-lg {
      @include bp-lg {
        border-right: 1px solid transparent !important;
      }
    }
  }
  &-width-1 {
    border-width: 1px !important;
    border-style: solid !important;
  }
  &-width-2 {
    border-width: 2px !important;
    border-style: solid !important;
  }
  &-width-3 {
    border-width: 3px !important;
    border-style: solid !important;
  }

  &-brand-primary {
    border-color: $brand-primary !important;
  }
  &-brand-secondary {
    border-color: $brand-secondary !important;
  }
  &-gray-200 {
    border-color: $gray-200 !important;
  }
  &-gray-300 {
    border-color: $gray-300 !important;
  }
  &-gray-400 {
    border-color: $gray-400 !important;
  }
  &-gray-500 {
    border-color: $gray-500 !important;
  }
  &-gray-600 {
    border-color: $gray-600 !important;
  }
  &-gray-700 {
    border-color: $gray-700 !important;
  }

  &-radius {
    border-radius: $border-radius-base;

    &-lg {
      border-radius: $border-radius-large;
    }

    &-sm {
      border-radius: $border-radius-small;
    }

    &-top-left-0 {
      border-top-left-radius: 0 !important;
    }
    &-top-right-0 {
      border-top-right-radius: 0 !important;
    }
    &-bottom-left-0 {
      border-bottom-left-radius: 0 !important;
    }
    &-bottom-right-0 {
      border-bottom-right-radius: 0 !important;
    }

  }
}
