.logout-link {
    &:before {
        content: '\f2f5';
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-right: .5rem;
        color: $gray-500;
    }
}

.user-menu.navbar-nav {
    margin: 2rem 0 2rem 1rem;

    @include bp-nav {
        margin: 0;
    }

    > li {
        > a {
            background: $gray-400 !important;
            border-radius: 50%;
            width: 3.5rem;
            height: 3.5rem;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: white;

            &:after {
                content: '\f107';
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                color: $gray-400;
                position: absolute;
                right: -1.5rem;
                font-size: 1.2rem;
                padding-left: .5rem;
            }
        }

        &:before {
            display: none;
        }

    }
    .dropdown-menu {
        border-radius: $border-radius-base !important;

        @include bp-nav {
            left: auto;
            right: -1.5rem;
            top: calc(100% + 1rem);
        }
        
    }
}

.primary-nav .nav {
    @extend .navbar-nav;
    font-size: 1.6rem;
    float: none;

    > li {
        @include bp-nav {
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }

    }

}