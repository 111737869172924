#views-exposed-form-tax-year-uploads-page-1 {
    .views-exposed-widgets {
        display: flex;
        align-items: center;
        @extend .row;

        &:before, &:after {
            display: none;
        }
    }
    .views-exposed-widget {
        padding: 0 1.5rem;
    }
}