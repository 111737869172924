/*===========================
    # Layout
 ===========================*/
html {
    height: 100%; // used to make footer always be at bottom (for short pages)
    overflow-x: hidden;
    font-size: 62.5%;
}

body {
    min-height: 100%;
    overflow-x: hidden;
    // used to make the footer always be at bottom (for short pages)
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
}

.main-container {
    //padding-top: 3rem;
    //padding-bottom: 3rem;
    max-width: 100%;
    min-height: 100%;

    @include bp-xs-max {
        width: 100%;
    }

    @include bp-md {
        flex: 1;
        display: flex;
    }

    &:before, &:after {
        display: none;
    }

    > .row {
        @include bp-md {
            display: flex;
            min-height: 100%;
            width: 100%;
            flex: 1;

            &:before, &:after {
                display: none;
            }
        }

    }

    .main-content {
        display: flex;
        flex-direction: column;
        //overflow-x: hidden;

        &:before, &:after {
            display: none;
        }

    }
}

.alert-block {
    &.alert-error {
        @extend .alert-warning;
    }
}

.node-type-client,
.node-type-property,
.node-type-parcel,
.node-type-tax-year,
.node-type-tax-year-parcel {
	.breadcrumb.fixed {
	  position: fixed;
	  top: 30px;
	  left: 0px;
	  width: 100%;
	  background-color: #2a2a30;
	  z-index: 100;
	  padding: 10px 20px;
	  border-radius: 0px;
	}
}