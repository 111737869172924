.page-search {
    .main-content {
        .search-form > div > .form-group {
            .btn-primary {
                padding: 1.05rem 2rem;
            }
        }

        .search-advanced {
            @extend .panel-icon;
            margin-top: 3rem;
            @extend .box-shadow--small;
            height: auto;

            .panel-heading {
                padding: 0;
                border: solid 1px $gray-300;
                margin-bottom: 0;

                .panel-title {
                    padding: 1rem 1.5rem;
                }
            }


            .panel-body {
                border-top: none !important;
            }
        }
    }
}