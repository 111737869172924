h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: .5px;
}


ul {
    padding-left: 1.3rem;
}

.lead {
    font-size: 1.6rem;
}

.small {
    font-size: 1.4rem;
}

.field-item {
    text-transform: uppercase;
}