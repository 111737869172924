/*
|--------------------------------------------------------------------------
| Accordion Panels
|--------------------------------------------------------------------------
*/
.panel:not(.panel-icon) {
    border: none;
    box-shadow: none;

    .panel-heading  {
        padding: 0;
        background: white;
        border: none;
        margin: 0;
    }

    .panel-title {
        padding: 1rem;
        display: block;
        font-family: $headings-font-family;
    }

    .panel-body {
        border-top: solid 1px $gray-400 !important;
    }
}

.panel-wrapper {
    &.full-width.bg-white .panel-data-row {
        @extend .border-gray-400;
    }
    .panel-data-row {
        @extend .border-gray-400;
    }
}


.panel {
    &-icon {
        [data-toggle="collapse"] {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            
            &[aria-expanded=true] {
                .show-text {
                    display: none;
                }
                .hide-text {
                    display: block;
                }
                &:after {
                    transform: rotate(180deg);
                    transition: all .2s;
                }
            }

            &:before {
                display: none;
            }
            
            &:after {
                content: '\f078';
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 1.8rem;
                margin-right: 0;
                color: $brand-primary;
            }

            .hide-text {
                display: none;
            }

            .action-text {
                margin-left: auto;
                margin-right: 1rem;
            }
        }
    }
}