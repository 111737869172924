.node-client_uploads-form {
    .file-widget {
        display: block;
        
        + .help-block {
            display: none;
        }
    }
    .panel-body {
        border: none !important;

        .form-item {
            margin: 0;
        }
    }
    .field-name-field-client, .tabbable, #edit-save-and-edit, #edit-preview, .field-name-field-documents .panel-title, .panel-body .control-label {
        @extend .sr-only;
    }
    .plupload_filelist_footer {
        height: auto;
    }
}

.view-display-id-block_1 .views-exposed-widget label { display: none; }