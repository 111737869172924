.tab-dropdown {
    position: relative;
    
    &-toggle {
        &.active {
            + .nav-tabs {
                display: block;
            }
        }
    }

    .nav-tabs {
        @extend .dropdown-menu;
        right: 0;
        left: auto;

        li {
            float: none;
            margin: 0;

            a {
                border-radius: 0 !important;
                margin: 0 !important;
                border: none !important;
            }
        }
    }
}