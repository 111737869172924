.navbar {
    margin-bottom: 0;
    border: none;
    box-shadow: 0px 1px 10px rgba(black, .4);
    position: relative;
    z-index: 99;
    > .container {
        @include bp-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:before, &:after {
                display: none;
            }
        }

    }

    .form-search, .search-form {
        > div > div {
            //border: solid 1rem $gray-800;
            display: flex;

            &:before, &:after {
                display: none;
            }

        }

        h2.element-invisible {
            @extend .sr-only;
        }

        .input-group-btn {
            display: none;
        }

        input {
            border-radius: $border-radius-small;
            box-shadow: none;
            border: none;
        }

        .input-group {
            width: calc(100% - 40px);
        }

        .form-actions, #edit-basic {
            margin-bottom: 0;

            .element-invisible.btn {
                position: static !important;
                //width: 20px;
                background: white;
                color: $brand-primary;
                height: 40px !important;
                border-width: 0;
                border-radius: $border-radius-small;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

    }
}

.navbar-header {
    float: none;
    margin: 0 !important;
    display: flex;
    align-items: center;

    &:before, &:after {
        display: none;
    }

    .logo {
        max-width: 70px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: auto;

        @include bp-md {
            margin-right: 1.5rem;
        }
    }
}

.navbar-collapse {
    order: 9999;

    @include bp-md {
        width: calc(100% - 60px);
    }
    

    nav {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        @include bp-md {
            margin-right: auto;
        }

    }

    #search-block-form {
        //position: absolute;
        //left: 100%;
        //right: -100%;
        //bottom: -60px;
        background: $gray-700;
        //bottom: calc(100% + 60px);
        transition: .2s all;

        @include bp-nav {
            padding: 0;
            margin-right: 1.5rem !important;
            width: 100%;
            max-width: 300px;
            margin-left: auto;
            padding-left: 1.5rem !important;
        }

        @include bp-lg {
            max-width: 400px;
        }
        


        > div {
            //margin: 0 auto;
            //
            //@include bp-sm {
            //    width: 750px;
            //}
            //
            //@include bp-md {
            //    width: auto;
            //}
        }

        @include bp-md {
            position: static;
            margin: 0;
            background: transparent;
            padding: 0;
        }

        &.active {
            //bottom: -60px;
            left: 0;
            right: 0;
        }
    }
}

.top-bar {
    background: $gray-700;

    a {
        color: white;
    }
}