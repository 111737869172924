.page--header {
    &--title {
        min-height: 4.4rem;
    }
    .nav-tabs {
    }
}















//.page-header {
//    display: flex;
//    align-items: flex-start;
//    flex-wrap: wrap;
//    flex-direction: column;
//
//    @include bp-md {
//        align-items: center;
//        flex-direction: row;
//    }
//
//    &:before, &:after {
//        display: none;
//    }
//
//    &--details {
//        margin-top: 1rem;
//
//        div {
//            display: inline;
//        }
//        
//        .field {
//            &:after {
//                content: '';
//                display: inline-block !important;
//                width: 5px;
//                height: 5px;
//                background: $gray-500;
//                border-radius: 50%;
//                margin-bottom: .3rem;
//                margin-left: 1rem;
//                margin-right: 1rem;
//                visibility: visible;
//            }
//            
//            &.field-name-field-address:after,
//            &.field-name-field-city:after,
//            &:last-child:after {
//                display: none !important;
//            }
//
//            &-label, strong {
//                font-weight: 300;
//            }
//        }
//    }
//}